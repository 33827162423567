<script>
import Vue from 'vue'
import { mapGetters } from '@/store'
import { version as webVersion } from '../../../package.json'
import { version as clientVersion } from '@sigmacloud/sigma-client/package.json'

export default Vue.extend({
    name: 'help',
    props: {
        instanceName: {
            type: String,
            required: true,
        },
        instanceURL: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            clientVersion,
            webVersion,
        }
    },
    computed: {
        ...mapGetters(['userResource', 'instanceURL', 'instanceName']),
    },
})
</script>

<template>
    <b-jumbotron
        header="ProBooks: Production Accounting in the Cloud"
        :lead="`Welcome to ProBooks${userResource.attributes.first_name && `, ${userResource.attributes.first_name}`}!`"
        header-level="5"
        bg-variant="white">
        <h4>Getting Started</h4>

        <ol>
            <li><router-link :to="{ name: 'accountList', params: { instanceURL } }">Add some accounts</router-link></li>
            <li><router-link :to="{ name: 'bankList', params: { instanceURL } }">Add a bank</router-link></li>
            <li>
                <router-link :to="{ name: 'companyList', params: { instanceURL } }">Add a company</router-link> (company will appear at the top of your checks)
            </li>
            <li><router-link :to="{ name: 'productionList', params: { instanceURL } }">Add a production</router-link></li>
            <li><router-link :to="{ name: 'journalEntryList', params: { instanceURL } }">Create a transaction</router-link> (NOT IMPLEMENTED)</li>
        </ol>

        <h4>Managing Costs</h4>

        <ol>
            <li><router-link :to="{ name: 'budgetList', params: { instanceURL } }">Create a budget</router-link></li>
            <li>
                <router-link :to="{ name: 'budgetLineList', params: { instanceURL } }">Specify budgets for specific accounts</router-link> (NOT IMPLEMENTED)
            </li>
        </ol>

        <h4>Notes</h4>

        <ol>
            <li>The reporting server will be going up and down for maintenance, so functionality will be limited at times.</li>
            <li>If you see a problem or have a suggestion, please contact support.</li>
            <li>Do not share your login credentials with <u>anyone</u>!</li>
        </ol>

        <b-badge variant="dark">Sigma-Client v{{ clientVersion }}</b-badge> <b-badge variant="dark">Sigma-Web v{{ webVersion }}</b-badge>
    </b-jumbotron>
</template>

<style lang="scss" scoped>
.jumbotron {
    padding: 32px;
}
</style>
